var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [_vm._v("Referral")]),
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "one-half" } },
            [
              _c("gov-body", [
                _vm._v(
                  "\n        Your " +
                    _vm._s(_vm.type) +
                    " can be set up to accept referrals through\n        " +
                    _vm._s(_vm.appName) +
                    ". These referrals directly connect your " +
                    _vm._s(_vm.type) +
                    " to\n        residents.\n      "
                )
              ]),
              _c(
                "gov-body",
                [
                  _vm._v(
                    "\n        If you are interested in turning on referrals for your organisation,\n        please\n        "
                  ),
                  _c(
                    "gov-link",
                    { attrs: { href: _vm.contactAdminTeamEmail } },
                    [_vm._v("contact the admin team")]
                  ),
                  _vm._v(".\n      ")
                ],
                1
              ),
              _c("gov-section-break", { attrs: { size: "l" } }),
              _c("ck-select-input", {
                attrs: {
                  value: _vm.referral_method,
                  id: "referral_method",
                  label: "Referral method",
                  hint:
                    "Does this " +
                    _vm.type +
                    " receive referrals, and if so, how?",
                  options: _vm.referralMethodOptions,
                  error: _vm.errors.get("referral_method"),
                  disabled: !_vm.isGlobalAdmin
                },
                on: {
                  input: function($event) {
                    _vm.$emit("update:referral_method", $event)
                    _vm.$emit("clear", "referral_method")
                  }
                }
              }),
              _vm.referralIsInternalOrExternal
                ? _c(
                    "ck-text-input",
                    {
                      attrs: {
                        value: _vm.referral_button_text,
                        id: "referral_button_text",
                        label: "What should your button say?",
                        type: "text",
                        error: _vm.errors.get("referral_button_text"),
                        disabled: !_vm.isGlobalAdmin
                      },
                      on: {
                        input: function($event) {
                          _vm.$emit("update:referral_button_text", $event)
                          _vm.$emit("clear", "referral_button_text")
                        }
                      }
                    },
                    [
                      _c(
                        "template",
                        { slot: "hint" },
                        [
                          _c(
                            "gov-hint",
                            { attrs: { for: "referral_button_text" } },
                            [
                              _vm._v(
                                "\n            E.g ‘Sign Up’, ‘Refer To’, or ‘Join In’\n          "
                              )
                            ]
                          ),
                          _vm.referral_method === "internal"
                            ? _c(
                                "gov-hint",
                                { attrs: { for: "referral_button_text" } },
                                [
                                  _vm._v(
                                    "\n            By enabling referrals through " +
                                      _vm._s(_vm.appName) +
                                      ", a button will be\n            added to your page which will link to the referral form.\n          "
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm.referral_method === "external"
                            ? _c(
                                "gov-hint",
                                { attrs: { for: "referral_button_text" } },
                                [
                                  _vm._v(
                                    "\n            This button will be added to your " +
                                      _vm._s(_vm.type) +
                                      " page, and link users\n            to the URL below.\n          "
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    2
                  )
                : _vm._e(),
              _vm.referralIsInternalOrExternal &&
              _vm.referral_method === "internal"
                ? _c("ck-text-input", {
                    attrs: {
                      value: _vm.referral_email,
                      id: "referral_email",
                      label:
                        "Where should we alert you when you receive a referral?",
                      hint:
                        "This email address should be a group inbox if possible. When a referral is received, someone will need to login to respond.",
                      type: "email",
                      error: _vm.errors.get("referral_email"),
                      disabled: !_vm.isGlobalAdmin
                    },
                    on: {
                      input: function($event) {
                        _vm.$emit("update:referral_email", $event)
                        _vm.$emit("clear", "referral_email")
                      }
                    }
                  })
                : _vm._e(),
              _vm.referralIsInternalOrExternal &&
              _vm.referral_method === "external"
                ? _c("ck-text-input", {
                    attrs: {
                      value: _vm.referral_url,
                      id: "referral_url",
                      label: "External referral URL",
                      hint:
                        "Please add the web address that the user will be directed to in order to make a referral.",
                      type: "url",
                      error: _vm.errors.get("referral_url"),
                      disabled: !_vm.isGlobalAdmin
                    },
                    on: {
                      input: function($event) {
                        _vm.$emit("update:referral_url", $event)
                        _vm.$emit("clear", "referral_url")
                      }
                    }
                  })
                : _vm._e(),
              _vm._t("default")
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }