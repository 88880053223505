var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("gov-heading", { attrs: { size: "l" } }, [
        _vm._v(_vm._s(_vm._f("ucfirst")(_vm.type)) + " details")
      ]),
      _c(
        "gov-grid-row",
        [
          _c(
            "gov-grid-column",
            { attrs: { width: "one-half" } },
            [
              _c("gov-body", [
                _vm._v(
                  "\n        General details about the " +
                    _vm._s(_vm.type) +
                    ". (We use " +
                    _vm._s(_vm.type) +
                    " in the\n        broadcast sense, This could be counciling or weekly yoga classes).\n      "
                )
              ]),
              _c("gov-section-break", { attrs: { size: "l" } }),
              _c("ck-select-input", {
                attrs: {
                  value: _vm.type,
                  id: "type",
                  label: "What is it?",
                  hint:
                    "This option changes how your page is described on " +
                    _vm.appName,
                  options: _vm.typeOptions,
                  error: _vm.errors.get("type")
                },
                on: {
                  input: function($event) {
                    _vm.$emit("update:type", $event)
                    _vm.$emit("clear", "type")
                  }
                }
              }),
              _c("ck-text-input", {
                attrs: {
                  value: _vm.name,
                  id: "name",
                  label: "What is the name of your " + _vm.type + "?",
                  type: "text",
                  error: _vm.errors.get("name")
                },
                on: {
                  input: function($event) {
                    return _vm.onNameInput($event)
                  }
                }
              }),
              _vm.auth.isGlobalAdmin
                ? _c(
                    "ck-text-input",
                    {
                      attrs: {
                        value: _vm.slug,
                        id: "slug",
                        label: "Unique slug",
                        type: "text",
                        error: _vm.errors.get("slug")
                      },
                      on: {
                        input: function($event) {
                          _vm.$emit("update:slug", $event)
                          _vm.$emit("clear", "slug")
                        }
                      }
                    },
                    [
                      _c(
                        "gov-hint",
                        { attrs: { slot: "hint", for: "slug" }, slot: "hint" },
                        [
                          _vm._v(
                            "\n          This will be used to access the " +
                              _vm._s(_vm.type) +
                              " page."
                          ),
                          _c("br"),
                          _vm._v(
                            "\n          e.g. example.com/services/" +
                              _vm._s(_vm.slug) +
                              "\n        "
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isNew || _vm.auth.isGlobalAdmin
                ? [
                    _vm.loading
                      ? _c("ck-loader")
                      : _c("ck-select-input", {
                          attrs: {
                            value: _vm.organisation_id,
                            id: "organisation_id",
                            label: "Organisation",
                            hint:
                              "Which organisation hosts this " + _vm.type + "?",
                            options: _vm.organisations,
                            error: _vm.errors.get("organisation_id")
                          },
                          on: {
                            input: function($event) {
                              _vm.$emit("update:organisation_id", $event)
                              _vm.$emit("clear", "organisation_id")
                            }
                          }
                        })
                  ]
                : _vm._e(),
              _c("ck-text-input", {
                attrs: {
                  value: _vm.url,
                  id: "url",
                  label: "What is the web address of your " + _vm.type + "?",
                  hint:
                    "This must start with ‘http://’ or ‘https://’. You can use your organisation’s website address if the " +
                    _vm.type +
                    " doesn’t have its own.",
                  type: "url",
                  error: _vm.errors.get("url")
                },
                on: {
                  input: function($event) {
                    _vm.$emit("update:url", $event)
                    _vm.$emit("clear", "url")
                  }
                }
              }),
              _vm.auth.isSuperAdmin
                ? _c("ck-select-input", {
                    attrs: {
                      value: _vm.score,
                      id: "score",
                      label: "Quality Score",
                      hint:
                        "Rate the overall effectiveness and quality of the " +
                        _vm.type +
                        " between 1 (poor) and 5 (excellent). This is not displayed but affects positioning within search results.",
                      options: _vm.scoreOptions,
                      error: _vm.errors.get("score")
                    },
                    on: {
                      input: function($event) {
                        _vm.$emit("update:score", $event)
                        _vm.$emit("clear", "score")
                      }
                    }
                  })
                : _vm._e(),
              _c("ck-radio-input", {
                attrs: {
                  value: _vm.national,
                  id: "national",
                  label: "Is the " + _vm.type + " a national " + _vm.type + "?",
                  options: _vm.nationalOptions,
                  error: _vm.errors.get("national")
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("update:national", $event)
                  }
                }
              }),
              _c("ck-select-input", {
                attrs: {
                  value: _vm.attending_type,
                  id: "attending_type",
                  label: "Attending type",
                  options: _vm.attendingTypeOptions,
                  error: _vm.errors.get("attending_type")
                },
                on: {
                  input: function($event) {
                    _vm.$emit("update:attending_type", $event)
                    _vm.$emit("clear", "attending_type")
                  }
                }
              }),
              _c("ck-select-input", {
                attrs: {
                  value: _vm.attending_access,
                  id: "attending_access",
                  label: "Attending access",
                  options: _vm.attendingAccessOptions,
                  error: _vm.errors.get("attending_access")
                },
                on: {
                  input: function($event) {
                    _vm.$emit("update:attending_access", $event)
                    _vm.$emit("clear", "attending_access")
                  }
                }
              }),
              _c(
                "ck-image-input",
                {
                  attrs: {
                    id: "logo",
                    label: "Upload your " + _vm.type + " logo",
                    "file-id": _vm.logo_file_id,
                    errors: _vm.errors
                  },
                  on: {
                    input: function($event) {
                      _vm.$emit("update:logo_file_id", $event.file_id)
                      _vm.$emit("update:logo", $event.image)
                    },
                    "image-changed": function($event) {
                      _vm.$emit("image-changed", $event)
                      _vm.$emit("clear", "file")
                    },
                    "alt-text-changed": function($event) {
                      _vm.$emit("alt-text-changed", $event)
                      _vm.$emit("clear", "alt_text")
                    }
                  }
                },
                [
                  _c(
                    "template",
                    { slot: "hint" },
                    [
                      _c(
                        "gov-hint",
                        { attrs: { for: "logo" } },
                        [
                          _vm._v(
                            "\n            This can be different to the logo of your organisation.\n            "
                          ),
                          _c(
                            "gov-link",
                            { attrs: { href: _vm.logoHelpHref } },
                            [_vm._v("Need help with your logo?")]
                          )
                        ],
                        1
                      ),
                      _c("gov-hint", { attrs: { for: "logo" } }, [
                        _vm._v(
                          "\n            If your " +
                            _vm._s(_vm.type) +
                            " doesn't have a logo, the site will use the\n            organisation logo if there is one uploaded.\n          "
                        )
                      ])
                    ],
                    1
                  )
                ],
                2
              ),
              _vm.auth.isGlobalAdmin
                ? _c("ck-radio-input", {
                    attrs: {
                      value: _vm.status,
                      id: "status",
                      label: "Is the " + _vm.type + " enabled",
                      hint:
                        "Indicates if the " +
                        _vm.type +
                        " is enabled or disabled (disabled " +
                        _vm.$options.filters.plural(_vm.type) +
                        " will not be shown in search results)",
                      options: _vm.statusOptions,
                      error: _vm.errors.get("status")
                    },
                    on: {
                      input: function($event) {
                        _vm.$emit("update:status", $event)
                        _vm.$emit("clear", "status")
                      }
                    }
                  })
                : _vm._e(),
              _c("ck-date-input", {
                attrs: {
                  id: "ends_at",
                  value: _vm.ends_at,
                  error: _vm.errors.get("ends_at"),
                  label: "End date",
                  hint:
                    "The date which this " +
                    _vm.type +
                    " should be made inactive"
                },
                on: {
                  input: function($event) {
                    _vm.$emit("update:ends_at", $event)
                    _vm.$emit("clear", "ends_at")
                  }
                }
              }),
              _c("gov-heading", { attrs: { size: "m" } }, [
                _vm._v("Gallery items")
              ]),
              _c("gov-body", [
                _vm._v(
                  "\n        Upload images of the " +
                    _vm._s(_vm.type) +
                    " to the " +
                    _vm._s(_vm.type) +
                    "'s gallery.\n      "
                )
              ]),
              _c("ck-gallery-items-input", {
                attrs: {
                  "gallery-items": _vm.gallery_items,
                  errors: _vm.errors
                },
                on: {
                  input: function($event) {
                    return _vm.$emit("update:gallery_items", $event)
                  },
                  clear: function($event) {
                    return _vm.$emit("clear", $event)
                  }
                }
              }),
              _vm.appServiceTagsActive
                ? [
                    _c("gov-heading", { attrs: { size: "m" } }, [
                      _vm._v("Tags")
                    ]),
                    _vm.auth.isGlobalAdmin
                      ? _c("gov-body", [
                          _vm._v(
                            "\n          Select tags to help users find the " +
                              _vm._s(_vm.type) +
                              ".\n        "
                          )
                        ])
                      : _vm._e(),
                    _c("tag-input", {
                      attrs: { "service-tags": _vm.tags, errors: _vm.errors },
                      on: {
                        input: function($event) {
                          return _vm.$emit("update:tags", $event)
                        },
                        clear: function($event) {
                          return _vm.$emit("clear", $event)
                        }
                      }
                    })
                  ]
                : _vm._e(),
              _vm._t("default")
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }