var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.galleryItems, function(galleryItem, index) {
        return _c(
          "gov-inset-text",
          { key: "Ck::GalleryItem::" + galleryItem.file_id },
          [
            _c("ck-image-input", {
              attrs: {
                id: "Ck::GalleryItemImage::" + galleryItem.file_id,
                label: "Upload an item to the gallery",
                "file-id": galleryItem.file_id,
                errors: _vm.errors,
                gallery: true
              },
              on: {
                input: function($event) {
                  return _vm.onGalleryItemInput($event, index)
                },
                "image-changed": function($event) {
                  _vm.$emit("image-changed", $event)
                  _vm.$emit("clear", "gallery_items." + index + ".file_id")
                },
                "alt-text-changed": function($event) {
                  _vm.alt_text = $event
                  _vm.$emit("clear", "gallery_items." + index + ".alt_text")
                },
                removed: function($event) {
                  return _vm.remove(index)
                }
              }
            }),
            _vm.errors.has("gallery_items." + index) ||
            _vm.errors.has("gallery_items." + index + ".file_id")
              ? _c("gov-error-message", {
                  attrs: { for: "Ck::GalleryItem::" + galleryItem.file_id },
                  domProps: {
                    textContent: _vm._s(
                      _vm.errors.get([
                        "gallery_items." + index,
                        "gallery_items." + index + ".file_id"
                      ])
                    )
                  }
                })
              : _vm._e(),
            _vm.errors.has("gallery_items")
              ? _c("gov-error-message", {
                  domProps: {
                    textContent: _vm._s(_vm.errors.get(["gallery_items"]))
                  }
                })
              : _vm._e()
          ],
          1
        )
      }),
      _c(
        "div",
        [
          _c(
            "gov-button",
            { on: { click: _vm.onAddGalleryItem } },
            [
              _vm.galleryItems.length === 0
                ? [_vm._v("Add gallery item")]
                : [_vm._v("Add another")]
            ],
            2
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }